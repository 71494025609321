<template>
    <div>
        <div class="information">
                <div>
                    <span style="font-weight: bold;"> Başvuru Tarihi: </span>
                    {{  dateFormat(data.createTime) }}
                </div>
                <div>
                    <span style="font-weight: bold;"> Başvuran İsmi: </span>
                    {{  data.name }}
                </div>
                <div>
                    <span style="font-weight: bold;"> Başvurulan Pozisyon: </span>
                    {{  data.careerPositionName }}
                </div>
                <div>
                    <span style="font-weight: bold;"> CV: </span>
                    <a :href="data.cvUrl" target="_blank"> CV'yi İndirin </a>
                </div>
                <div v-if="data.instagram !== ''">
                    <span style="font-weight: bold;"> Instagram: </span>
                    <a :href="'https://instagram.com/'+data.instagram" target="_blank"> @{{ data.instagram }} </a>
                </div>
                <div v-if="data.twitter !== ''">
                    <span style="font-weight: bold;"> Twitter: </span>
                    <a :href="'https://x.com/'+data.twitter" target="_blank"> @{{ data.twitter }} </a>
                </div>
                <div v-if="statusList.findIndex(e => e.value == data.status) > -1 ">
                    <span style="font-weight: bold;"> Başvuru Durumu: </span>
                    <CBadge :color="statusList.find(e => e.value == data.status).variant"> 
                        {{ statusList.find(e => e.value == data.status).label }} 
                    </CBadge>
                </div>
                <div v-else>
                    {{data.status}}
                </div>
                <div>
                    <span style="font-weight: bold;"> Ön Yazı: </span>
                    {{  data.coverLetter }}
                </div>
        </div>

        <CForm ref="form" class="needs-validation">
            <CRow>
                <CCol col="6">
                    <CInput
                        label="E-mail"
                        placeholder="E-mail Adresi"
                        type="text"
                        v-model="data.emailAddress"
                        horizontal
                    />
                </CCol>
                <CCol col="6">
                    <CInput
                        label="Telefon"
                        placeholder="Telefon Numarası"
                        type="text"
                        v-model="data.phoneNumber"
                        horizontal
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <CTextarea
                        label="Not"
                        placeholder="Not"
                        type="text"
                        v-model="data.note"
                        horizontal
                        rows="10"
                    />
                </CCol>
            </CRow>
            
        </CForm>
    </div>
   
</template>

<script>
    import moment from 'moment'
    export default{
        name: "careerApplicantForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        computed:{
            statusList: function() {
                return this.$store.getters.careerStatusTypes;
            },
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
        methods:{
            dateFormat: function(val){
                return moment(val).format('DD.MM.YYYY HH:mm')
            }
        }
    }
</script>
<style>
    .information{
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .information div{
        margin: .5rem;
    }
</style>